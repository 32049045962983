import * as amplitude from '@amplitude/analytics-browser';

export const registerToBidEvent = (isAuthenticated, email) => {
  amplitude.track(
    `${process.env.NODE_ENV === 'development' ? 'dev-' : ''} Register to bid`,
    { email: isAuthenticated ? email : '-' }
  );
};

export const trackLots = (lotName, email, auctionName) => {
  const eventProperties = {
    email_address: email,
    lot_name: lotName,
    auction_name: auctionName,
  };
  amplitude.track('View lots page', eventProperties);
};

export const trackLognIn = email => {
  if (localStorage.getItem('login') === 'isLoggingIn') {
    amplitude.track('Successful log in ', { email_address: email });
    amplitude.setUserId(email);
    localStorage.removeItem('login');
  }
};
export const trackEvent = (eventName, eventProperties) => {
  amplitude.track(eventName, eventProperties);
};

export const amplitudeEvents = {
  VIEW_AUCTION_PAGE: 'View auction page',
  VIEW_LOTS_LIST_PAGE: 'View lots list page',
  VIEW_PRIVATE_SALES_PAGE: 'View Private Sales page',
  VIEW_CONSIGNMENT_PAGE: 'View Consign Page',
  SUBMIT_YOUR_VEHICLE_CONSIGN: 'Click on Submit Your Vehicle to Consign',
  SUBMIT_CONSIGN_FORM: 'Submit car consignment form',
  VIEW_CATALOGUE_PAGE: 'View Catalogue page',
  VIEW_TERMS_CONDITIONS: 'View Terms and Conditions',
  VIEW_HOME_PAGE: 'View Home Page',
  VIEW_COLLECTIONS: 'View Collections & Estates ',
  VIEW_CONTACT_US: 'View Contact Us',
  VIEW_REGISTER_TO_BID: 'View Register To Bid',
  EMAIL_CLICK: 'Email Link Click',
  PHONE_CLICK: 'Phone Number Click',
  VIEW_MEDIA: 'View Media',
  VIEW_ABOUT_US: 'View About Us',
  VIEW_ABOUT_US: 'View About Us',
  VIEW_ORDER_CATALOGUE: 'View Order a Catalogue ',
  BE_FIRST_TO_KNOW: 'Be the First to Know Form Submission',
  SOLD_FORM_SUBMISSION: 'Have Questions About This Past Sale? Form Submission',
  GOOGLE_PLAY_CLICK: 'Download Google Play Click',
  ADD_TO_CART_CATALOGUE: 'Add to Carts Button Click for Catalogues',
  VIEW_CART_CATALOGUE: 'View Shopping Cart Button Click',
  SUBMIT_PAYMENT: 'Click the “Submit Payment” button',
  AUCTION_TYPE_FILTER: 'Auction Type filter',
  FAVORITES: 'Favorite Click',
};
export const pagesTitles = {
  HOME_PAGE: 'Home Page',
  CONTACT_US: 'Contact Us',
  COLLECTIONS_ESTATES: 'Collections And Estates',
  CONSIGN: 'Consign',
  REGISTER_TO_BID: 'Register to Bid',
  ORDER_CATALOGUE: 'Order a Catalogue',
};
