export const REGISTER_TO_BID_PATH = 'register';
export const VEHICLE_PATH = slug => `/lot/${slug}`;
export const CONSIGNMENT_PATH = 'consign';
export const AUCTION_PATH = slug => `/auction/${slug}`;
export const PRIVACY_POLICY_PATH = 'privacy';

export const FAQ_PATH = 'faq';

export const VEHICLES_CATALOG_PATH = 'lots';

export const TERMS_PATH = 'terms';

export const CAREERS_PATH = 'careers';

export const KIOSK_PATH = 'auth/kiosk';
export const KIOSK_REGISTER_PATH = 'auth/kiosk/register-online';

export const PRIVATE_VEHICLE_PATH = slug => `/private-vehicle/${slug}`;
